// grey series
$grey-darker: #424242;
$mid-grey-dark: #757575;
$mid-grey-medium: #8b8b8b;
$mid-grey: #bdbdbd;
$mid-grey-light: #e0e0e0;
$grey-light: #eeeeee;
$white: #ffffff;
$black: #000000;
$orange: #ffa500;
$red: #ff0000;
$yellow: #eeee00;

// main color
$main-blue: #2a4670;
$menu-color: #001529;
// $menu-color: #0a75bc;
$header-color: $white;
$content-color: $white;
$breadcrumb-color: #f0f2f5;
$link-primary: #097ecc;

$danger-level1: #ff6542;
$danger-level2: #bb342f;
$warning-level1: #fcb97d;
$warning-level2: #faa613;

$textinput-label: #04293d;
$textinput-value: #101426;
$textinput-placeholder: #d5e2f5;

$bookmark-type-a: $red;
$bookmark-type-b: $orange;
$bookmark-type-c: $yellow;

// button
$button-drafting: #91caff;
$button-existed: #52d4ff;
$button-pending: #faa613;
$button-requested: #faa613;
$button-approved: #00bcd4;
$button-rejected: #ff6666;
$button-transacted: #cc66cc;
$button-bought: #33cc00;
$button-deleted: #cc0000;
$button-received: #33ccff;
$button-duplicated: #faa613;
$button-finished: #00bcd4;
$button-restored: #78d750;
$button-changeable-price: #cc66cc;
$button-valuation-price: #33cc00;
$button-request-valuate-price: #faa613;
$button-sale: #33cccc;
$button-share: #7000cc;
$button-processing: #33cc00;
$button-processed: #33ccff;
$button-cancel: #cc0000;

$version-data: $red;

$app-colors: (
  drafting: $button-drafting,
  existed: $button-existed,
  pending: $button-pending,
  requested: $button-requested,
  approved: $button-approved,
  rejected: $button-rejected,
  transacted: $button-transacted,
  bought: $button-bought,
  deleted: $button-deleted,
  received: $button-received,
  duplicated: $button-duplicated,
  finished: $button-finished,
  restored: $button-restored,
  changeable-price: $button-changeable-price,
  valuation-price: $button-valuation-price,
  request-valuate-price: $button-request-valuate-price,
  sale: $button-sale,
  share: $button-share,
  processing: $button-processing,
  processed: $button-processed,
  cancel: $button-cancel
);
