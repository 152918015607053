@import './assets/theme/palette/colours.scss';

.App {
  @each $name, $color in $app-colors {
    .button-#{$name} {
      background-color: $color;
      border-color: $color;
      opacity: 0.85;
      color: white;
    }
  }

  .ant-input-disabled {
    color: $textinput-value;
  }
  .ant-select-disabled {
    background-color: $grey-light;
    span {
      color: $textinput-value;
    }
  }
  .ant-picker-disabled {
    background-color: $grey-light;
    input {
      color: $textinput-value;
    }
  }

  .ant-form-item-label {
    white-space: pre-wrap;
  }

  .ant-picker {
    width: 100%;
  }

  .ant-form-item-label {
    label {
      display: initial;
    }
  }
}
.ant-modal-root {
  .ant-picker {
    width: 100%;
  }
  .full-parent-width {
    width: 100%;
  }
}

.ant-btn-icon-only {
  line-height: 0px;
}

.edit-btn {
  color: #40a9ff;
  border-color: #40a9ff;
}

.add-btn {
  color:  #40a9ff !important;
  border-color:  #40a9ff !important;
  &:hover, &:focus, &:active {
    color:  #40a9ff !important;
    border-color:  #40a9ff !important;
  }
}

.ant-modal-header, .ant-modal-content {
  border-radius: 20px !important;
}

.remove-btn {
  color: #ff4d4f;
  border-color: #ff4d4f;
  &:hover, &:focus, &:active {
    color: #ff4d4f;
    border-color: #ff4d4f;
  }
}

.cancel-btn {
  color: #7b7b7b;
  border-color: #c2c2c2;
  &:hover, &:focus, &:active {
    color: #7b7b7b;
    border-color: #c2c2c2;
  }
}

.collapse-wrap {
  .ant-collapse-content > .ant-collapse-content-box {
    padding: 0 !important;
  }
}

/* width */
::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.ant-form-item-required::before {
  position: absolute;
  right: -15px;
}

.ant-form-item-required::after {
  margin-right: 0px !important;
}

.ant-form-item {
  margin-bottom: 10px;
}

.ant-input {
  font-size: 13px;
}

.ant-form-item-label label {
  font-size: 13px;
}

.ant-select {
  font-size: 13px;
}

.ant-picker-input input {
  font-size: 13px;
}

.ant-alert-description {
  font-size: 13px;
}

.ant-form-item-explain-error {
  font-size: 10px;
}

.ant-form-item-has-error {
  height: 40px;
}

.ant-table-cell {
  padding: 4px !important;
}

.ant-table {
  font-size: 12px;
}

.ant-table-thead {
  height: 40px !important;
}

.ant-image-preview-img-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  position: none;
  top: none;
  right: none;
  bottom: none;
  left: none;
}

.ant-popover-inner {
  border-radius: 7px;
}

.image-left {
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
  height: 100vh;
  opacity: 0.8;
}

.sider-container .ant-menu-root {
  overflow: auto;
  height: calc(100vh - 60px);
  left: 0;
}

.ant-layout-header {
  background: #001529 !important;
  color: white !important;
  padding: 0 10px;
}

.ant-card-head {
  background-color: #f5f5f5;
  border-radius: 10px 10px 0px 0px;
}

.ant-form-item-control-input-content {
  input {
    border-radius: 6px;
  }
}

.ant-select-selector, .ant-picker, .ant-pagination-item, .ant-pagination-item-link, .ant-pagination-next, input, textarea, .ant-tag, .ant-btn:not(.ant-btn-circle) {
  border-radius: 6px !important;
}
.ant-card-head {
  min-height: 36px;
  padding: 0 24px;
}
// .aaaa {
//   padding: 16px 16px 8px 16px;
// }
.ant-card-head-title {
  padding: 8px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  h4 {
    margin-bottom: 0px !important;
  }
}
.ant-btn-primary {
  min-width: 80px;
}
.ant-pagination-item-link {
  span {
    svg {
      margin-top: -6px;
    }
  }
}
.mr-b-2 {
  margin-right: 0.9em !important;
}
.ml-b-2 {
  margin-left: 0.9em !important;
}
h2.ant-typography {
  font-size: 24px;
}

.ant-collapse>.ant-collapse-item>.ant-collapse-header {
  padding: 12px 0px;
}

h2 {
  font-size: 1.68em;
  font-weight: 400;
}

h3 {
  font-size: 1.36em;
  font-weight: 400;
}

h4 {
  font-size: 1.2em;
  font-weight: 400;
}

.ck.ck-editor__editable {
  border: 1px solid #d9d9d9 !important;
}

a[target=_blank] {
  color: #1890ff !important;
}

input:read-only, textarea:read-only {
  cursor: pointer !important;
}

a {
  word-break: unset !important;
}

.ant-table-content::-webkit-scrollbar {
  height: 10px;
}