@import '../../../assets/theme/index.scss';

.sider-extend {
  min-width: 200px;
  width: 200px;
  transition: all 0.2s;
}

.sider-collapsed {
  width: 80px;
  min-width: 80px;
  transition: all 0.2s;
}

.sider-container {
  background: $menu-color;
  min-height: 100vh;
  max-height: 100vh;
  // border-radius: 0px 30px 0px 0px;
  position: fixed;
  z-index: 101;
  .ant-layout-logo {
    background-color: $menu-color;
    // border-radius: 0px 30px 0px 0px;
    height: 64px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    .ant-layout-logo-image {
      height: 64px;
    }
  }
  .sider-trigger {
    position: absolute;
    top: 25px;
    right: -23px;
    width: 25px;
    height: 25px;
    line-height: 20px;
    text-align: center;
    color: $white;
    background: $menu-color;
    border-radius: 0 4px 4px 0;
    cursor: pointer;
    .anticon {
      line-height: 25px;
    }
  }
}
